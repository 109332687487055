* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ProductHeader {
    width: 100%;
    background-color: #041e42;
    height: 50px;
    text-align: center;
    line-height: 50px;
    padding-top: 10px;
    padding-bottom: 5px;
}

.ProductHeader img {
    max-height: 90%;
    max-width: 90%;
    display: inline-block;
}

.container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    font-family: 'Roboto', sans-serif;

}

h1 {
    width: 50%;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    color: #404040;
    flex: 70%;
    float: left;
    padding: 0;
    line-height: 18px;
    margin-top: 0;
    margin-bottom: 0;
    min-height: 42px;
}

button.button.vipps {
    background-image: url("./assets/vipps_hurtigkasse_rect_250_NO@2x.png");
    background-repeat: no-repeat;
    background-color: #FF5B23;
    background-size: 80% auto;
    background-position: center center;
    border-radius: 10px;
    width: 100%;
    text-indent: -9999px;
    height: 50px;
    border: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
    margin-bottom: 10px;

}

.button.vipps:hover {
    opacity: 0.85 !important;
    cursor: pointer;
}

button.vipps.success {
    text-indent: 0;
    opacity: 1;
    background: #5E8462;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.image-container img {
    width: auto;
    height: 100%;
    max-height: 250px !important;
}

.image-container {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    width: 250px;
    min-height: 254px;
    max-width: 320px;
    overflow: hidden;
    border: 2px solid #979797;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
    margin-bottom: 40px;

}

.product-price {
    flex: 30%;
    font-weight: 600;
    font-size: 18px;
    text-align: right;
    line-height: 18px;

}

.current-price {
    color: #e8a942;
    display: inline-block;
}

.old-price {
    text-decoration: line-through;
    display: inline-block;
    font-weight: 300;
    font-size: 16px;
}

.row {
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
}

.description-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid #DCDCDC;
    border-radius: 10px;
    margin-top: 18px;
    position: relative;
    margin-bottom: 20px;
}

.description-container p {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}

.description-container li {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}

.description-container strong, .description-container b {
    font-weight: 400;
}

.accordion__item {
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
    cursor: pointer;

}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 9px;
    width: 9px;
    margin-right: 12px;
    border-bottom: 2px solid #404040;
    border-right: 2px solid #404040;
    transform: rotate(-45deg);
    position: absolute;
    right: 20px;
    top: 20px;
}

.vipps:disabled {
    opacity: 0.8 !important;
}

.accordion__button[aria-expanded='true']::before, .accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
    top: 18px;

}

.accordion__header {
    color: #404040;
    font-family: Roboto, sans-serif;
    font-size: 14px;
}

.product-counter {
    width: 100%;
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: #040404;
    margin-top: 18px;
    margin-bottom: 18px;
}

.product-counter .minus, .product-counter .plus {
    width: 30px;
    height: 30px;
    border: 0;
    text-indent: -999px;
    cursor: pointer;
    outline: none;
    line-height: 16px;
    display: inline-block;
    border-radius: 5px;
    background-size: 60% 60%;
}

.product-counter .minus:hover, .product-counter .plus:hover {
    background-color: #ECEFF166;
}

.current {
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    width: 30px;
    text-align: center;
    color: #2C393F;
}

.minus {
    background: url("./assets/minus.svg") no-repeat;
    background-color: #ECEFF1;
    background-position: center center;
    margin-left: 20px;
}

.plus {
    background: url("./assets/plus.svg") no-repeat;
    background-color: #ECEFF1;
    background-position: center center;
}

.additional-information {
    font-size: 11px;
    width: 100%;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    color: #404040
}


.additional-information .row {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    vertical-align: middle;


}

.additional-information .first-col {
    flex: 1 0 65%;
    align-items: center;
    justify-content: center;
    vertical-align: middle;


}

.additional-information .second-col {
    text-align: right;
    vertical-align: middle;

    flex: 33%;
    color: #5E8462;
    font-size: 11px;
}

#retur {
    width: 18px;
    margin-right: 5px;
}

#card-logo {
    width: 16px;
    margin-right: 5px;
}

#vipps-logo {
    width: 60px;
    position: absolute;
    margin-top: -6px
}

.summary-price {
    font-weight: 300;
    display: inline-block;
    margin-left: 10px;
}


.shipping-details {
    width: 100%;
    min-height: 70px;
    background-image: repeating-linear-gradient(134deg, #404040, #404040 4px, transparent 4px, transparent 8px, #404040 8px), repeating-linear-gradient(224deg, #404040, #404040 4px, transparent 4px, transparent 8px, #404040 8px), repeating-linear-gradient(-46deg, #404040, #404040 4px, transparent 4px, transparent 8px, #404040 8px), repeating-linear-gradient(44deg, #404040, #404040 4px, transparent 4px, transparent 8px, #404040 8px);
    background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    border-radius: 5px;
    margin-top: 18px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.shipping-row span {
    display: block;
    margin-left: 25px;
    color: #5E8462;
}

.shipping-row {
    font-size: 11px;
    font-weight: 500;
}

#delivery-icon {
    margin-right: 5px;
    display: inline-block
}

#helthem-icon {
    margin-left: 3px;
    position: relative;
    top: 2px
}

.success {
    border: 1px solid #7FB685;
    color: #404040;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 5px;
    margin-bottom: 20px;
}

.cancel {
    border: 1px solid #912727;
    color: #404040;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 5px;
    margin-bottom: 20px;
}

.success strong {
    font-size: 18px;
    display: inline-block;
    margin-bottom: 10px;

}

.success span {
    display: inline-block;
    font-size: 14px;
    color: #707070;
    font-weight: 300;
}

.button-wrapper {
    position: relative;
}

.BrainhubCarousel__dots {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 5px !important;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot {
    background: transparent;
}

.BrainhubCarousel__dot--selected:before {
    background: #FF5B23 !important;
}


.modal {
    font-size: 14px;
    color: #011627;
    background-color: #fff;
    box-shadow: 0 -5px 55px rgba(50, 50, 50, 0.9);
    border-bottom: none;
    position: fixed;
    bottom: -45px;
    left: 0;
    right: 0;
    height: 245px;
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    text-align: justify;
    font-weight: 300;

}

.modal button:hover {
    background: #5E8462;
}

.modal button {
    border: 0;
    background: #7FB685;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    position: absolute;
    right: 20px;
    bottom: 15px;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

#privacy img {
    position: relative;
    top: 3px;
    margin-right: 5px;
}

#privacy {
    text-decoration: underline;
    color: #5E8462;
    font-weight: 500;
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 0px;
    font-size: 13px;
}

#privacy:hover {
    text-decoration: none;
}

.ReactModal__Overlay {
    background-color: rgba(10, 10, 10, 0.5) !important;
}

.ReactModal__Content {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    padding-bottom: 60px !important;
    inset: 30px !important;
    bottom: 50% !important;
    top: auto !important;
}

.ReactModal__Content button {
    border: 0;
    background: #7FB685;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    position: absolute;
    right: 20px;
    bottom: 15px;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}
